// eslint-disable-next-line no-shadow
export enum PRIORITY {
  TRIVIAL = 'trivial',
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
  CRITICAL = 'critical',
  BLOCKER = 'blocker'
}

export interface PriorityOption {
  label: string;
  value: PRIORITY;
}

export const PRIORITY_OPTIONS: PriorityOption[] = [
  {
    label: 'Trivial',
    value: PRIORITY.TRIVIAL
  },
  {
    label: 'Low',
    value: PRIORITY.LOW
  },
  {
    label: 'Medium',
    value: PRIORITY.MEDIUM
  },
  {
    label: 'High',
    value: PRIORITY.HIGH
  },
  {
    label: 'Critical',
    value: PRIORITY.CRITICAL
  },
  {
    label: 'Blocker',
    value: PRIORITY.BLOCKER
  }
];
