import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEntityUpdate } from 'snq/hooks/useEntityUpdate';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  makeStyles,
  SvgIcon,
  Typography,
  Grid,
  Box,
  TextField,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import { TaskProduct } from 'snq/model';
import { Edit } from 'react-feather';

const useStyles = makeStyles(() => ({
  dialog: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '500px',
    width: '30vw',
    height: '450px'
  }
}));
interface EditTaskProductProps {
  taskProduct: TaskProduct;
  apiRoute: string;
  icon?: boolean;
  iconSize?: 'small' | 'default' | 'large';
  onRefresh?: any;
}

export const EditTaskProductButton = ({
  taskProduct,
  apiRoute,
  icon,
  iconSize,
  onRefresh
}: EditTaskProductProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [quantity, setQuantity] = React.useState(taskProduct.quantity);
  const [ratio, setRatio] = React.useState(taskProduct.ratio);
  const [invoiceable, setInvoiceable] = React.useState(taskProduct.invoiceable);
  const { update } = useEntityUpdate<TaskProduct, TaskProduct>({
    apiRoute,
    id: taskProduct.id.toString()
  });

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    if (onRefresh) onRefresh(Math.random());
    setOpen(false);
  };

  return (
    <>
      {icon ? (
        <IconButton onClick={handleOpen}>
          <SvgIcon fontSize={iconSize || 'default'}>
            <Edit />
          </SvgIcon>
        </IconButton>
      ) : (
        <Button variant="contained" color="primary" onClick={handleOpen}>
          {t('Edit')}
        </Button>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="edit-dialog-title"
        aria-describedby="edit-dialog-description"
      >
        <div className={classes.dialog}>
          <DialogTitle id="edit-dialog-title" disableTypography>
            <Typography variant="h4">{`${t('Edit')} ${
              taskProduct.product.name
            }`}</Typography>
          </DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item xs={12} md={12}>
                <Box mb={1} ml={1} mr={1}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Description
                  </Typography>
                  <Typography variant="h6" color="textPrimary">
                    {taskProduct.product.description}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box mb={1} ml={1} mr={1}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Category
                  </Typography>
                  <Typography variant="h6" color="textPrimary">
                    {taskProduct.product.category}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box mb={3} ml={1} mr={1}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Price
                  </Typography>
                  <Typography variant="h6" color="textPrimary">
                    {`$${taskProduct.product.price}`}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box mb={3} ml={1} mr={1}>
                  <TextField
                    fullWidth
                    value={quantity}
                    onChange={(v) => setQuantity(+v.target.value)}
                    variant="outlined"
                    type="number"
                    label="Quantity"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box mb={3} ml={1} mr={1}>
                  <TextField
                    fullWidth
                    value={ratio}
                    onChange={(v) => setRatio(+v.target.value)}
                    variant="outlined"
                    type="number"
                    label="Ratio"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box ml={1} mr={1}>
                  <FormControlLabel
                    label="Invoiceable"
                    onChange={(e, v) => setInvoiceable(v)}
                    control={
                      <Checkbox checked={invoiceable} name="invoiceable" />
                    }
                  />
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
        </div>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            {t('Cancel')}
          </Button>
          <Button
            onClick={() => {
              const data = {
                ...taskProduct,
                quantity,
                ratio,
                invoiceable
              };
              update(data);
              handleClose();
            }}
            variant="contained"
            color="secondary"
          >
            {t('Confirm Edit')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
