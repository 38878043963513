// eslint-disable-next-line no-shadow
export enum STATUS {
  DRAFT = 'draft',
  READY = 'ready',
  IN_PROGRESS = 'in_progress',
  VALIDATING = 'validating',
  ACCEPTED = 'accepted',
  COMPLETED = 'completed'
}

export interface StatusOption {
  label: string;
  value: STATUS;
}

export const STATUS_OPTIONS: StatusOption[] = [
  {
    label: 'Draft',
    value: STATUS.DRAFT
  },
  {
    label: 'Ready',
    value: STATUS.READY
  },
  {
    label: 'In Progress',
    value: STATUS.IN_PROGRESS
  },
  {
    label: 'Validating',
    value: STATUS.VALIDATING
  },
  {
    label: 'Accepted',
    value: STATUS.ACCEPTED
  },
  {
    label: 'Completed',
    value: STATUS.COMPLETED
  }
];
