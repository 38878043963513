/* eslint-disable react/jsx-props-no-spreading, @typescript-eslint/ban-types */
import React from 'react';
import MaUTable from '@material-ui/core/Table';
import {
  Box,
  Card,
  CardContent,
  Grid,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import {
  Column,
  Row,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable
} from 'react-table';
import { DEFAULT_PAGE_SIZE, SORT_TYPE } from 'snq/model';
import PerfectScrollbar from 'react-perfect-scrollbar';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import { useLocation } from 'react-router';
import { Trans } from 'react-i18next';
import { isEmpty } from 'lodash';
import { Center } from 'components/StyledComponents';
import { SNQSortBtn } from './SNQSortBtn';
import { SNQSearchForm } from './SNQSearchForm';
import { SNQTabFilter } from './SNQTabFilters';

interface TabFilter {
  value: string;
  label: string;
}

interface TableListNoSelectableProps<T extends Object> {
  columns: Array<Column<T>>;
  tabs?: Array<TabFilter>;
  data: T[];
  fetchData;
  loading: boolean;
  totalObjects: number;
  actionColumn?: React.FC<ActionColumnProps<T>>;
  nestedKey?: string;
  refreshToken?: string;
  filter?: string;
  searchFilter?: boolean;
}

export interface ActionColumnProps<T extends Object> {
  row: Row<T>;
}

export function SNQTableListNoSelectable<T extends Object>({
  columns,
  data,
  fetchData,
  loading,
  totalObjects,
  searchFilter,
  refreshToken,
  tabs,
  actionColumn: ActionColumn
}: TableListNoSelectableProps<T>) {
  const searchBox = searchFilter === undefined ? true : searchFilter;
  const location = useLocation();
  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    // Get the state from the instance
    state: { pageIndex, pageSize, sortBy }
  } = useTable<T>(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: DEFAULT_PAGE_SIZE },
      manualPagination: true,
      manualSortBy: true,
      manualGlobalFilter: true,
      globalFilter: location.search
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const [currentPage, setCurrentPage] = React.useState(pageIndex);
  const [size, setSize] = React.useState(pageSize);
  const [filter, setFilter] = React.useState('all');

  React.useEffect(() => {
    fetchData({
      page: currentPage + 1,
      perPage: size,
      sortBy,
      search: location.search,
      filter
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, size, sortBy, location.search, refreshToken, filter]);

  if (loading) {
    return null;
  }

  // Render the UI for your table
  return (
    <Card>
      {tabs && (
        <SNQTabFilter filter={filter} setFilter={setFilter} tabs={tabs} />
      )}
      <Grid container spacing={3}>
        <Grid item xs={6} />
        <Grid item xs={6}>
          {searchBox && <SNQSearchForm />}
        </Grid>
      </Grid>
      <CardContent>
        <PerfectScrollbar>
          <Box minWidth={700}>
            <MaUTable {...getTableProps()}>
              <TableHead>
                {headerGroups.map((headerGroup) => (
                  // eslint-disable-next-line react/jsx-key
                  <TableRow {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => {
                      // eslint-disable-next-line @typescript-eslint/dot-notation
                      const tabCells = column['removeSorting'] ? (
                        // eslint-disable-next-line react/jsx-key
                        <TableCell key={column.id}>
                          <Trans>{column.render('Header')}</Trans>
                        </TableCell>
                      ) : (
                        // eslint-disable-next-line react/jsx-key
                        <TableCell
                          key={column.id}
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          <Trans>{column.render('Header')}</Trans>
                          <SNQSortBtn
                            direction={
                              column.isSortedDesc
                                ? SORT_TYPE.desc
                                : SORT_TYPE.asc
                            }
                            isSorted={column.isSorted}
                          />
                        </TableCell>
                      );
                      return tabCells;
                    })}
                    {ActionColumn && (
                      <TableCell align="right">
                        <Trans>Actions</Trans>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody>
                {page.map((row: Row<T>) => {
                  prepareRow(row);
                  return (
                    <TableRow {...row.getRowProps()} key={row.id}>
                      {row.cells.map((cell) => {
                        return (
                          // eslint-disable-next-line react/jsx-key
                          <TableCell {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </TableCell>
                        );
                      })}
                      {!!ActionColumn && (
                        <TableCell align="right">
                          <ActionColumn row={row} />
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </MaUTable>
          </Box>
        </PerfectScrollbar>
        {isEmpty(data) ? (
          <Center>
            <Typography variant="body2" color="textSecondary">
              <Trans>No records found</Trans>
            </Typography>
          </Center>
        ) : (
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={totalObjects}
            rowsPerPage={size}
            page={currentPage}
            onChangePage={(event, newPage) => {
              setCurrentPage(newPage);
            }}
            onChangeRowsPerPage={(e) => {
              setSize(Number(e.target.value));
            }}
          />
        )}
      </CardContent>
    </Card>
  );
}
