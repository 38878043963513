import { useState } from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { Languages, LOCAL_STORAGE_KEYS, Option } from 'snq/model';

export const getTranslationResource = (key: string) => {
  return fetch(`/locales/${key}/translation.json`)
    .then((response) => response.json())
    .then((data) => data);
};

export const getLastLanguage = () => {
  try {
    return (
      JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.lastLanguage)) ||
      Languages[0]
    );
  } catch (e) {
    return Languages[0];
  }
};

export const loadLanguage = (key: string) => {
  return getTranslationResource(key).then((data) => {
    i18next.addResourceBundle(key, 'translation', data);
  });
};

export const useCurrentLanguage = () => {
  const { i18n } = useTranslation();
  const [language, updateLang] = useState<Option>(getLastLanguage());
  const setLanguage = (lang: Option) => {
    loadLanguage(String(lang.value));
    i18n.changeLanguage(String(lang.value));
    localStorage.setItem(LOCAL_STORAGE_KEYS.lastLanguage, JSON.stringify(lang));
    updateLang(lang);
  };
  return {
    setLanguage,
    language
  };
};
