import { lazy } from 'react';
import { PATH_CORE_NOT_FOUND } from 'snq/helpers/paths/core';

export const STATIC_MODULE = [
  {
    exact: true,
    path: PATH_CORE_NOT_FOUND,
    component: lazy(() => import('views/core/errors/NotFoundView'))
  }
];
