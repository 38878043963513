import React from 'react';
import { IconButton, SvgIcon } from '@material-ui/core';
import { Edit } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';

interface EditButtonProps {
  path: string;
  onClick?: (e: React.ChangeEvent<any>) => void;
}

export const SNQEditIconButton = ({ path, onClick }: EditButtonProps) => (
  <IconButton component={RouterLink} to={path} onClick={onClick}>
    <SvgIcon fontSize="small">
      <Edit />
    </SvgIcon>
  </IconButton>
);
