import { Tab, Tabs } from '@material-ui/core';
import React, { ChangeEvent, useState } from 'react';

interface TabFilter {
  value: string;
  label: string;
}

interface TabFilterProps {
  filter: string;
  setFilter: (filter) => void;
  tabs: Array<TabFilter>;
}

export function SNQTabFilter({ filter, setFilter, tabs }: TabFilterProps) {
  const [currentTab, setCurrentTab] = useState<string>(filter);
  const handleTabsChange = (
    event: ChangeEvent<HTMLInputElement>,
    value: string
  ): void => {
    const updatedFilters = {
      hasAcceptedMarketing: null,
      isProspect: null,
      isReturning: null
    };

    if (value !== 'all') {
      updatedFilters[value] = true;
    }

    setFilter(value);
    setCurrentTab(value);
  };

  return (
    <Tabs
      onChange={handleTabsChange}
      scrollButtons="auto"
      textColor="secondary"
      value={currentTab}
      variant="scrollable"
    >
      {tabs.map((tab) => (
        <Tab key={tab.value} value={tab.value} label={tab.label} />
      ))}
    </Tabs>
  );
}
