import { SNQBreadcrumbPath } from 'snq/components/Breadcrumb/SNQBreadcrumb';
import {
  getAdministrationBreadcrumb,
  PATH_ADMINISTRATION
} from 'snq/helpers/path';

export const PATH_ORGANIZATIONS = `${PATH_ADMINISTRATION}/organizations`;
export const PATH_ORGANIZATIONS_CREATE = `${PATH_ORGANIZATIONS}/create`;
export const PATH_ORGANIZATIONS_DETAIL = `${PATH_ORGANIZATIONS}/:organizationId`;
export const PATH_ORGANIZATIONS_EDIT = `${PATH_ORGANIZATIONS}/:organizationId/edit`;
export const PATH_ORGANIZATIONS_AUTHORIZATIONS_DETAILS = `${PATH_ORGANIZATIONS_DETAIL}/authorizations/:authorizationId`;
export const PATH_ORGANIZATIONS_TEAMS_DETAILS = `${PATH_ORGANIZATIONS_DETAIL}/teams/:teamId`;
export const PATH_ORGANIZATIONS_DELEGATIONS_DETAILS = `${PATH_ORGANIZATIONS_DETAIL}/delegations/:delegationId`;
export const PATH_ORGANIZATIONS_LOCATIONS_DETAILS = `${PATH_ORGANIZATIONS_DETAIL}/locations/:locationId`;

export const getOrganizationBreadcrumb = (): SNQBreadcrumbPath[] => {
  return [
    ...getAdministrationBreadcrumb(),
    {
      path: PATH_ORGANIZATIONS,
      label: 'Organizations'
    }
  ];
};

export const getOrganizationTeamBreadcrumb = (): SNQBreadcrumbPath[] => {
  return [
    ...getOrganizationBreadcrumb(),
    {
      path: PATH_ORGANIZATIONS,
      label: 'Teams'
    }
  ];
};
