export const apiConfig = {
  returnRejectedPromiseOnError: true,
  timeout: 1000,
  baseURL: process.env.REACT_APP_BASE_API_URL,
  headers: {
    common: {
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      Pragma: 'no-cache',
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }
  }
};
