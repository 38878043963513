import { SNQBreadcrumbPath } from 'snq/components/Breadcrumb/SNQBreadcrumb';
import {
  buildPathParams,
  getManagementBreadcrumb,
  PATH_MANAGEMENT
} from 'snq/helpers/path';

export const PATH_PROJECTS = `${PATH_MANAGEMENT}/projects`;
export const PATH_PROJECTS_CREATE = `${PATH_PROJECTS}/create`;
export const PATH_PROJECTS_DETAIL = `${PATH_PROJECTS}/:projectId`;
export const PATH_PROJECTS_BOARD_DETAIL = `${PATH_PROJECTS}/:projectId/board/:boardId`;
export const PATH_PROJECTS_EDIT = `${PATH_PROJECTS}/:id/edit`;

export const getProjectBreadcrumb = (): SNQBreadcrumbPath[] => {
  return [
    ...getManagementBreadcrumb(),
    {
      path: PATH_PROJECTS,
      label: 'Projects'
    }
  ];
};

export const getProjectDetailsBreadcrumb = (
  projectId: string,
  projectName: string
): SNQBreadcrumbPath[] => {
  return [
    ...getManagementBreadcrumb(),
    {
      path: buildPathParams({ projectId }, PATH_PROJECTS_DETAIL),
      label: projectName
    }
  ];
};
