import React, { lazy } from 'react';
import { Trans } from 'react-i18next';
import { Home as HomeIcon, PieChart as PieChartIcon } from 'react-feather';
import { Redirect } from 'react-router-dom';
import { PATH_DASHBOARD } from 'snq/helpers/path';
import {
  PATH_REPORTS_DASHBOARD,
  PATH_REPORTS_DASHBOARD_ALTERNATIVE
} from 'snq/helpers/paths/reports';

export const REPORT_MODULE = [
  {
    exact: true,
    path: PATH_DASHBOARD,
    component: function NotFound() {
      return <Redirect to={PATH_REPORTS_DASHBOARD} />;
    }
  },
  {
    exact: true,
    path: PATH_REPORTS_DASHBOARD,
    component: lazy(() => import('views/reports/DashboardView'))
  },
  {
    exact: true,
    path: PATH_REPORTS_DASHBOARD_ALTERNATIVE,
    component: lazy(() => import('views/reports/DashboardAlternativeView'))
  }
];

export const REPORT_NAVBAR = [
  {
    subheader: <Trans>Reports</Trans>,
    enabled: true, // TODO: Enable when valid
    items: [
      {
        title: <Trans>Dashboard</Trans>,
        icon: PieChartIcon,
        href: PATH_REPORTS_DASHBOARD_ALTERNATIVE,
        enabled: true // TODO: Enable when valid
      },
      {
        title: <Trans>Projects</Trans>,
        icon: HomeIcon,
        href: PATH_REPORTS_DASHBOARD,
        enabled: true // TODO: Enable when valid
      }
    ]
  }
];
