import { enumToArray } from 'snq/helpers/enumToArray';
import { BaseEntity } from './baseEntity';
import { Address } from './address';

export interface Identity extends BaseEntity {
  identifier: string;
  kind: string;
  country: string;
  name: string;
  email: string;
  address?: Address;
  verified: boolean;
  phone: string;
  id: number;
  relationId?: number;
  logoWide?: string;
}

export interface IdentityEmail extends BaseEntity {
  description: string;
  createdAt: string;
}

export interface IdentityLog extends BaseEntity {
  description: string;
  ip: string;
  method: string;
  route: string;
  status: number;
  createdAt: string;
}

// eslint-disable-next-line no-shadow
export enum IdentityKind {
  personal = 1,
  business = 2
}

export const IDENTITY_KIND_OPTIONS = enumToArray(IdentityKind);

export const IDENTITY_EXTENDED_OPTIONS = [
  { code: 'personal', label: 'Personal', id: '1' },
  { code: 'business', label: 'Business', id: '2' }
];
