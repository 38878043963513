import React, { useCallback } from 'react';
import { IconButton, SvgIcon } from '@material-ui/core';
import { Trash } from 'react-feather';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useSnackbar } from 'notistack';
import { useDeleteRelation } from 'snq/hooks/useDeleteRelation';

interface DeleteRelationProps {
  path: string;
  onClose?: any;
}

export const DeleteRelationButton = ({
  path,
  onClose
}: DeleteRelationProps) => {
  const [open, setOpen] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const api = useDeleteRelation<null, null>({
    apiRoute: path
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const confirmRemoval = useCallback(
    ({ setErrors, setStatus }) => {
      const result = api.deleteRelation(null);
      if (api.error) {
        setStatus({ success: false });
        setErrors({ submit: api.error });
      }
      setOpen(false);
      enqueueSnackbar('Organization Updated', {
        variant: 'success'
      });
      onClose(Math.random());
      return result;
    },
    [api, enqueueSnackbar, onClose]
  );

  return (
    <>
      <IconButton onClick={handleClickOpen}>
        <SvgIcon fontSize="small">
          <Trash />
        </SvgIcon>
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to delete this authorization?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            By removing this authorization from the list, the user won't be able
            to view, or perform any action for this organization. You can always
            invite the user again.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Disagree
          </Button>
          <Button onClick={confirmRemoval} color="secondary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
