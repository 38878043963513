import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import {
  getLastLanguage,
  loadLanguage
} from 'snq/hooks/core/useCurrentLanguage';

const lastLanguage = getLastLanguage();

loadLanguage(lastLanguage.value);

export default i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {},
    lng: lastLanguage.value,
    keySeparator: false,
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    debug: true
  });
