import type { FC } from 'react';
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Container,
  makeStyles,
  Typography
} from '@material-ui/core';
import type { Theme } from 'theme';

interface CTAProps {
  className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingTop: 128,
    paddingBottom: 128
  },
  browseButton: {
    marginLeft: theme.spacing(2)
  }
}));

const CTA: FC<CTAProps> = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Container maxWidth="lg">
        <Typography variant="h1" align="center" color="textPrimary">
          ¿Listo para empezar a invertir?
        </Typography>
        <Typography variant="h1" align="center" color="secondary">
          Contacta a nuestro equipo de asesores hoy mismo.
        </Typography>
        <Box mt={6} display="flex" justifyContent="center" alignItems="center">
          <Button color="secondary" component="a" href="#" variant="contained">
            Contactar
          </Button>
        </Box>
      </Container>
    </div>
  );
};

CTA.propTypes = {
  className: PropTypes.string
};

export default CTA;
