const base = process.env.REACT_APP_BASE_API_URL;

export const API_PATH = {
  // entities
  ORGANIZATIONS: `${base}/organizations`,
  IDENTITIES: `${base}/identities`,
  ADDRESSES: `${base}/addresses`,
  USERS: `${base}/users`,
  // Financials
  INVOICES: `${base}/invoices`,
  TAXES: `${base}/taxes`,
  // Management
  PROJECTS: `${base}/projects`,
  PRODUCTS: `${base}/products`
};

// export
type Keys = keyof typeof API_PATH;
export type API_PATH_Values = typeof API_PATH[Keys];
