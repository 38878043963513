import { Organization, Product, User } from 'snq/model';
import { BaseEntity } from 'snq/model/baseEntity';
import {
  ProjectActivity,
  ProjectApplicant,
  ProjectFile,
  ProjectReview
} from 'types/project';

export interface Project extends BaseEntity {
  id: number;
  title: string;
  description: string;
  status: string;
  identifier: string;
  leader: User;
  // Temp types
  activities?: ProjectActivity[];
  applicants?: ProjectApplicant[];
  organizations: Organization[];
  project_users: ProjectAuthorization[];
  budget: number;
  caption: string;
  currency: string;
  endDate?: number;
  files?: ProjectFile[];
  image?: string;
  isActive?: boolean;
  isLiked: boolean;
  likesCount?: number;
  location: string;
  members?: Member[];
  membersCount?: number;
  rating: number;
  reviews?: ProjectReview[];
  startDate?: number;
  tags?: string[]; //
  technology?: string; //
  type: 'Full-Time' | 'Part-Time';
  updatedAt: number;
}

export interface Task extends BaseEntity {
  id: number;
  title: string;
  project_id: number;
  description: string;
  status: string;
  issuer: string;
  priority: string;
  due_date: string;
  assignee: string;
  relationId?: number;
  project: Project;
}

export interface TaskProduct {
  id: number;
  ratio: number;
  rate: number;
  quantity: number;
  invoiceable: boolean;
  product: Product;
  task: Task;
}

// eslint-disable-next-line no-shadow
export enum ProjectStatus {
  draft = 'draft',
  ready = 'sent',
  in_progress = 'canceled',
  validating = 'paid',
  accepted = 'accepted',
  completed = 'compelted'
}

export interface ProjectAuthorization {
  user: User;
  role: string;
  commitment: number;
  project: Project;
  id?: string;
}

export interface Member {
  id: string;
  avatar: string;
  bio: string;
  name: string;
  user: User;
  role: string;
  display_name: string;
  email: string;
}
