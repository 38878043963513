/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import { useIsMountedRef } from 'hooks/ui/useIsMountedRef';
import { API_PATH_Values } from 'snq/helpers/api';
import { BaseError } from 'snq/model/baseEntity';
import { useHeaders } from './useHeaders';

interface useDeleteRelationConfig<P> {
  mainKey?: string;
  apiRoute: API_PATH_Values;
  method?: 'DELETE';
  data?: P;
}

interface useDeleteRelationAPI<T, P> {
  data?: T;
  error: BaseError;
  loading: boolean;
  deleteRelation: (
    params: P
  ) => Promise<{ data?: T; error: BaseError; loading: boolean }>;
}

export const useDeleteRelation = <T, P>({
  apiRoute,
  method = 'DELETE'
}: useDeleteRelationConfig<P>): useDeleteRelationAPI<T, P> => {
  const isMountedRef = useIsMountedRef();
  const [{ error, loading, data }, setResult] = useState({
    data: undefined,
    error: undefined,
    loading: false
  });
  const hookHeaders = useHeaders({});

  const deleteRelation = async (params: P) => {
    try {
      setResult({
        data: undefined,
        error: undefined,
        loading: true
      });

      const headers = await hookHeaders.getHeaders();

      const response = await fetch(apiRoute, {
        body: JSON.stringify(params),
        cache: 'no-cache',
        headers,
        method
      });

      if (response.status >= 300) throw Error(`Error code: ${response.status}`);
      const result = await response.json();

      if (response.status >= 300) {
        setResult({
          data: undefined,
          error: result.error,
          loading: false
        });
        return {
          data: undefined,
          error: result.error,
          loading: false
        };
      }
      if (isMountedRef.current) {
        setResult({
          data: result,
          error: undefined,
          loading: false
        });
      }
      return {
        data: result,
        error: undefined,
        loading: false
      };
    } catch (err) {
      setResult({
        data: undefined,
        error,
        loading: false
      });
      return {
        data,
        error,
        loading
      };
    }
  };

  return {
    data,
    error,
    loading,
    deleteRelation
  };
};
