import React from 'react';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { SNQInputText } from 'snq/components';
import { useCurrentLanguage } from 'snq/hooks/core/useCurrentLanguage';
import { Languages, Option } from 'snq/model';

export const SNQLanguageSelector = () => {
  const { t } = useTranslation();
  const { setLanguage, language } = useCurrentLanguage();
  const handleSelect = (e, option: Option) => {
    if (option) setLanguage(option);
  };
  return (
    <Autocomplete
      getOptionLabel={(o) => o.label}
      options={Languages}
      onChange={handleSelect}
      value={language}
      disableClearable
      renderInput={(params) => (
        <SNQInputText
          label={t('Select language')}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
        />
      )}
    />
  );
};
