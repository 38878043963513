/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import { useIsMountedRef } from 'hooks/ui/useIsMountedRef';
import { API_PATH_Values } from 'snq/helpers/api';
import { BaseError } from 'snq/model/baseEntity';
import { useHeaders } from './useHeaders';

interface useEntityUpdateConfig<P> {
  mainKey?: string;
  id: string;
  apiRoute: API_PATH_Values;
  data?: P;
}

interface useEntityUpdateAPI<T, P> {
  data?: T;
  error: BaseError;
  loading: boolean;
  update: (
    params: P
  ) => Promise<{ data?: T; error: BaseError; loading: boolean }>;
}

const getData = (mainKey?: string, data?: any) =>
  mainKey && data && data[mainKey] ? data[mainKey] : data;

export const useEntityUpdate = <T, P>({
  mainKey,
  apiRoute,
  id
}: useEntityUpdateConfig<P>): useEntityUpdateAPI<T, P> => {
  const isMountedRef = useIsMountedRef();
  const hookHeaders = useHeaders({});
  const [{ error, loading, data }, setResult] = useState({
    data: undefined,
    error: undefined,
    loading: false
  });

  const update = async (params: P) => {
    try {
      setResult({
        data: undefined,
        error: undefined,
        loading: true
      });

      const headers = await hookHeaders.getHeaders();

      const response = await fetch(`${apiRoute}/${id}`, {
        body: JSON.stringify(params),
        cache: 'no-cache',
        headers,
        method: 'PATCH'
      });

      const result = await response.json();

      if (response.status >= 300) {
        setResult({
          data: undefined,
          error: result.error,
          loading: false
        });
        return {
          data: undefined,
          error: result.error,
          loading: false
        };
      }
      if (isMountedRef.current) {
        setResult({
          data: getData(mainKey, result),
          error: undefined,
          loading: false
        });
      }
      return {
        data: getData(mainKey, result),
        error: undefined,
        loading: false
      };
    } catch (err) {
      setResult({
        data: undefined,
        error,
        loading: false
      });
      return {
        data,
        error,
        loading
      };
    }
  };

  return {
    data,
    error,
    loading,
    update
  };
};
