import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  makeStyles,
  SvgIcon,
  Typography
} from '@material-ui/core';
import { Trash } from 'react-feather';

const useStyles = makeStyles(() => ({
  dialog: {
    display: 'flex',
    flexDirection: 'column',
    width: '350px',
    height: '200px'
  }
}));
interface DeleteButtonProps {
  title: string;
  description?: string;
  icon?: boolean;
  iconSize?: 'small' | 'default' | 'large';
  onAccept: () => void;
  onRefresh?: any;
}

export const SNQDeleteButton = ({
  title,
  description,
  icon,
  iconSize,
  onAccept,
  onRefresh
}: DeleteButtonProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {icon ? (
        <IconButton onClick={handleOpen}>
          <SvgIcon fontSize={iconSize || 'default'}>
            <Trash />
          </SvgIcon>
        </IconButton>
      ) : (
        <Button variant="contained" color="primary" onClick={handleOpen}>
          {t('Delete')}
        </Button>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <div className={classes.dialog}>
          <DialogTitle id="delete-dialog-title" disableTypography>
            <Typography variant="h4">{`${t('Delete')} ${title}`}</Typography>
          </DialogTitle>
          {description && (
            <DialogContent>
              <DialogContentText id="delete-dialog-description">
                {description}
              </DialogContentText>
            </DialogContent>
          )}
        </div>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            {t('Cancel')}
          </Button>
          <Button
            onClick={() => {
              onAccept();
              if (onRefresh) onRefresh(Math.random());
              handleClose();
            }}
            variant="contained"
            style={{ color: 'white', background: 'red' }}
          >
            {t('Delete')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
