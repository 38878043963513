import React, { lazy } from 'react';
import { Box as BoxIcon } from 'react-feather';
import { Trans } from 'react-i18next';
import {
  PATH_PRODUCTS,
  PATH_PRODUCTS_CREATE,
  PATH_PRODUCTS_DETAIL,
  PATH_PRODUCTS_EDIT
} from 'snq/helpers/paths/products';

export const COMMERCE_MODULE = [
  {
    exact: true,
    path: PATH_PRODUCTS,
    component: lazy(() => import('views/commerce/products/ProductListView'))
  },
  {
    exact: true,
    path: PATH_PRODUCTS_CREATE,
    component: lazy(() => import('views/commerce/products/ProductCreateView'))
  },
  {
    exact: true,
    path: PATH_PRODUCTS_DETAIL,
    component: lazy(() => import('views/commerce/products/ProductDetailsView'))
  },
  {
    exact: true,
    path: PATH_PRODUCTS_EDIT,
    component: lazy(() => import('views/commerce/products/ProductEditView'))
  }
];

export const COMMERCE_NAVBAR = [
  {
    subheader: <Trans>Commerce</Trans>,
    enabled: true, // TODO: Enable when valid
    items: [
      {
        title: <Trans>Products</Trans>,
        href: PATH_PRODUCTS,
        icon: BoxIcon,
        enabled: true // TODO: Enable when valid
      }
    ]
  }
];
