import { SNQBreadcrumbPath } from 'snq/components/Breadcrumb/SNQBreadcrumb';
import { getProjectDetailsBreadcrumb, PATH_PROJECTS_DETAIL } from './projects';

export const PATH_TASKS = `${PATH_PROJECTS_DETAIL}/tasks`;
export const PATH_TASKS_DETAIL = `${PATH_TASKS}/:taskId`;
export const PATH_TASKS_EDIT = `${PATH_TASKS}/:taskId/edit`;

export const getTaskBreadcrumb = (
  projectId: string,
  projectName: string
): SNQBreadcrumbPath[] => {
  return [
    ...getProjectDetailsBreadcrumb(projectId, projectName),
    {
      path: PATH_TASKS,
      label: 'Tasks'
    }
  ];
};
