// eslint-disable-next-line no-shadow
export enum MAGNITUDE {
  M2 = 'M2',
  M3 = 'M3',
  KG = 'KG',
  UNIT = 'UNIT',
  PACK = 'PACK',
  HOUR = 'HOUR'
}

export interface MagnitudeOptions {
  label: string;
  value: MAGNITUDE;
}

export const MAGNITUDE_OPTIONS: MagnitudeOptions[] = [
  {
    label: MAGNITUDE.M2,
    value: MAGNITUDE.M2
  },
  {
    label: MAGNITUDE.M3,
    value: MAGNITUDE.M3
  },
  {
    label: MAGNITUDE.PACK,
    value: MAGNITUDE.PACK
  },
  {
    label: MAGNITUDE.UNIT,
    value: MAGNITUDE.UNIT
  },
  {
    label: MAGNITUDE.KG,
    value: MAGNITUDE.KG
  },
  {
    label: MAGNITUDE.HOUR,
    value: MAGNITUDE.HOUR
  }
];
