import { useHistory, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { LOCAL_STORAGE_KEYS } from 'snq/model';
import { snqlogerror } from 'snq/helpers';

let cache = false;

export const useRedirectToLatestRoute = () => {
  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    if (cache) {
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.lastLocation,
        JSON.stringify(location)
      );
    }
  }, [location]);

  useEffect(() => {
    if (!cache) {
      try {
        cache = true;
        const lastLocation = JSON.parse(
          localStorage.getItem(LOCAL_STORAGE_KEYS.lastLocation)
        ) as Location;
        history.push(lastLocation.pathname);
      } catch (e) {
        snqlogerror('unable to redirect', e);
      }
    }
  }, [history]);
};
