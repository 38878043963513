// eslint-disable-next-line no-shadow
export enum ROLE {
  READER = 'reader',
  ADMINISTRATOR = 'administrator'
}

export interface RoleOption {
  label: string;
  value: ROLE;
}

export const ROLE_OPTIONS: RoleOption[] = [
  {
    label: 'Reader',
    value: ROLE.READER
  },
  {
    label: 'Administrator',
    value: ROLE.ADMINISTRATOR
  }
];
