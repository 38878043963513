import type { FC } from 'react';
import React from 'react';
import { Typography } from '@material-ui/core';

interface LogoProps {
  [key: string]: any;
}

const Logo: FC<LogoProps> = (props) => {
  return (
    <Typography
      variant="h4"
      color="textPrimary"
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...props}
    >
      inverti.xyz
    </Typography>
  );
};

export default Logo;
