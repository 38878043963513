import type { FC } from 'react';
import React, { useRef, useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import { useSnackbar } from 'notistack';
import {
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  makeStyles,
  Menu,
  MenuItem,
  Typography
} from '@material-ui/core';
import { useAuth } from 'hooks';
import { PATH_ACCOUNT } from 'snq/helpers/path';
import { snqlogerror } from 'snq/helpers';

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1)
  },
  popover: {
    width: 200
  }
}));

const Account: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const ref = useRef<any>(null);
  const { user, logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      await logout({
        returnTo: window.location.origin
      });
      history.push('/');
    } catch (err) {
      snqlogerror(err);
      enqueueSnackbar('Unable to logout', {
        variant: 'error'
      });
    }
  };

  return (
    <>
      <div ref={ref}>
        <Box
          display="flex"
          alignItems="center"
          component={ButtonBase}
          onClick={handleOpen}
        >
          <Avatar alt="User" className={classes.avatar} src={user.avatar} />
          <Hidden smDown>
            <Typography variant="h6" color="inherit">
              {user.name}
            </Typography>
          </Hidden>
        </Box>
      </div>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem component={RouterLink} to={PATH_ACCOUNT}>
          Account
        </MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </>
  );
};

export default Account;
