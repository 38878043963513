import React, { ReactElement } from 'react';
import { Button, SvgIcon } from '@material-ui/core';
import { PlusCircle } from 'react-feather';
import { Link } from 'react-router-dom';

interface NewButtonProps {
  children: string | ReactElement;
  path?: string;
  onClick?: (e: React.ChangeEvent<any>) => void;
  disabled?: boolean;
  type?: 'button' | 'submit';
}

export const SNQNewButton = ({
  children = 'Create',
  path,
  onClick,
  disabled,
  type = 'button'
}: NewButtonProps) => {
  return (
    <Button
      color="secondary"
      variant="contained"
      startIcon={
        <SvgIcon fontSize="small">
          <PlusCircle />
        </SvgIcon>
      }
      to={path}
      onClick={onClick}
      component={Link}
      disabled={disabled}
      type={type}
    >
      {children}
    </Button>
  );
};
