import { CURRENCY } from 'snq/model/currencies';
import { Product } from 'snq/model/product';
import { BaseEntity } from './baseEntity';
import { MAGNITUDE, MagnitudeOptions } from './magnitudes';

interface InvoiceCustomer {
  address?: string;
  company?: string;
  email: string;
  name: string;
  taxId?: string;
}

interface InvoiceProvider {
  address?: string;
  company?: string;
  email: string;
  name: string;
  taxId?: string;
}

export interface InvoiceItem {
  id?: number;
  title: string;
  description: string;
  quantity: number;
  price: number;
  magnitude: string;
  product_id: string;
  taxes?: number;
}

// eslint-disable-next-line no-shadow
export enum InvoiceStatus {
  draft = 'draft',
  sent = 'sent',
  canceled = 'canceled',
  paid = 'paid'
}

export interface Invoice extends BaseEntity {
  identifier: string;
  currency: string;
  issuer: InvoiceCustomer;
  recipient: InvoiceProvider;
  dueDate?: number;
  issueDate?: number;
  items?: InvoiceItem[];
  number?: string;
  status: InvoiceStatus;
}

export interface Tax extends BaseEntity {
  id: number;
  country: number;
  state: string;
  entity_kind: number;
  name: string;
  rate: number;
}

export interface InvoiceItemsRequestParams {
  title: string;
  description: string;
  quantity: number;
  price: number;
  magnitude: MAGNITUDE;
  product_id?: number;
  category?: number;
  taxes?: number;
}

export interface InvoiceRequestParams {
  invoice: {
    identifier: string;
    issuer_id: number;
    recipient_id: number;
    notes: string;
    due_date: string;
    payment_date: string;
    status: InvoiceStatus;
    currency: CURRENCY;
    invoice_items_attributes?: InvoiceItemsRequestParams[];
  };
}

export interface InvoiceItemStateForm {
  id: string;
  title: string;
  description?: string;
  quantity: number;
  price: number;
  tax: Tax;
  magnitude: MagnitudeOptions;
  product?: Product;
  category?: string;
}
