import { sumBy } from 'lodash';
import { Tax } from 'snq/model';

interface ItemProductGeneric {
  price: number;
  quantity: number;
  tax?: Tax;
  taxes?: number;
}

export const getTaxRate = (taxes: number) => taxes || 0;

export const getItemNoTax = (item: ItemProductGeneric) => {
  if (!item) return 0;
  return (
    (parseFloat(String(item.price || 0)) || 0) *
    (parseFloat(String(item.quantity || 1)) || 1)
  );
};

export const getItemTotal = (item: ItemProductGeneric) => {
  if (!item) return 0;
  return (
    getItemNoTax(item) *
    ((100 + getTaxRate(item.taxes || item.tax?.rate)) / 100)
  );
};

export const getSubtotalItems = (items: ItemProductGeneric[] = []) => {
  return sumBy(items, getItemNoTax);
};

export const getTotalItems = (items: ItemProductGeneric[] = []) => {
  return sumBy(items, getItemTotal);
};

export const getTotalTaxItems = (items: ItemProductGeneric[] = []) => {
  if (getTotalItems(items) < getSubtotalItems(items)) return 0;
  return getTotalItems(items) - getSubtotalItems(items);
};

export const getPercentTotalTaxItems = (items: ItemProductGeneric[] = []) => {
  if (getTotalItems(items) < getSubtotalItems(items)) return 0;
  return 100 - (getSubtotalItems(items) * 100) / getTotalItems(items);
};

export const formatPrice = (p) => Number(p).toFixed(2);
