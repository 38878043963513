import { SNQBreadcrumbPath } from 'snq/components/Breadcrumb/SNQBreadcrumb';

// ONLY MAIN FOLDERS
export const PATH_DASHBOARD = '/admin';
export const PATH_ACCOUNT = `${PATH_DASHBOARD}/account`;
export const PATH_ADMINISTRATION = `${PATH_DASHBOARD}/administration`;
export const PATH_MANAGEMENT = `${PATH_DASHBOARD}/management`;
export const PATH_REPORTS = `${PATH_DASHBOARD}/reports`;
export const PATH_COMMERCE = `${PATH_DASHBOARD}/commerce`;

export const buildPathParams = (
  params: { [key: string]: string },
  path: string
) =>
  Object.keys(params).reduce(
    (currentPath, key) => currentPath.replace(`:${key}`, params[key]),
    path
  );

export const getDashboardBreadcrumb = (): SNQBreadcrumbPath[] => {
  return [
    {
      path: PATH_DASHBOARD,
      label: 'Dashboard'
    }
  ];
};

export const getAdministrationBreadcrumb = (): SNQBreadcrumbPath[] => {
  return [
    ...getDashboardBreadcrumb(),
    {
      path: PATH_ADMINISTRATION,
      label: 'Administration'
    }
  ];
};

export const getManagementBreadcrumb = (): SNQBreadcrumbPath[] => {
  return [
    ...getDashboardBreadcrumb(),
    {
      path: PATH_MANAGEMENT,
      label: 'Management'
    }
  ];
};

export const getCommerceBreadcrumb = (): SNQBreadcrumbPath[] => {
  return [
    ...getDashboardBreadcrumb(),
    {
      path: PATH_COMMERCE,
      label: 'Commerce'
    }
  ];
};
