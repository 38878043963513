import AuthGuard from 'components/AuthGuard';
import LoadingScreen from 'components/LoadingScreen';
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout';
import MainLayout from 'layouts/MainLayout';
import React, { ElementType, Fragment, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { PATH_DASHBOARD } from 'snq/helpers/path';
import { ACCOUNT_MODULE } from 'snq/modules/account';
import { ADMINISTRATION_MODULE } from 'snq/modules/navbar/administration';
import { CORE_MODULE } from 'snq/modules/core';
import { INVOICING_MODULE } from 'snq/modules/navbar/invoicing';
import { MANAGEMENT_MODULE } from 'snq/modules/navbar/management';
import { REPORT_MODULE } from 'snq/modules/navbar/reports';
import { STATIC_MODULE } from 'snq/modules/static';
import HomeView from 'views/core/home/HomeView';
import { PATH_CORE_NOT_FOUND } from 'snq/helpers/paths/core';
import { COMMERCE_MODULE } from './snq/modules/navbar/commerce';

type Routes = {
  exact?: boolean;
  path?: string | string[];
  guard?: ElementType;
  layout?: ElementType;
  component?: ElementType;
  routes?: Routes;
}[];

export const renderRoutes = (routes: Routes = []): JSX.Element => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes: Routes = [
  ...CORE_MODULE,
  {
    path: PATH_DASHBOARD,
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      ...ACCOUNT_MODULE,
      ...INVOICING_MODULE,
      ...COMMERCE_MODULE,
      ...ADMINISTRATION_MODULE,
      ...MANAGEMENT_MODULE,
      ...REPORT_MODULE,
      ...STATIC_MODULE,
      {
        component: function NotFound() {
          return <Redirect to={PATH_CORE_NOT_FOUND} />;
        }
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: HomeView
      },
      {
        component: function NotFound() {
          return <Redirect to={PATH_CORE_NOT_FOUND} />;
        }
      }
    ]
  }
];

export default routes;
