import type { HTMLProps, ReactNode } from 'react';
import React, { forwardRef, useCallback, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import type { Theme } from 'theme';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import track from 'utils/analytics';

interface PageProps extends HTMLProps<HTMLDivElement> {
  children?: ReactNode;
  title?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  pageContainer: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));

export const SNQPage = forwardRef<HTMLDivElement, PageProps>(
  function SNQPageComp({ children, title = '', ...rest }, ref) {
    const location = useLocation();
    const classes = useStyles();
    const sendPageViewEvent = useCallback(() => {
      track.pageview({
        page_path: location.pathname
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      sendPageViewEvent();
    }, [sendPageViewEvent]);

    return (
      <div ref={ref as any} {...rest} className={classes.pageContainer}>
        <Helmet>
          <title>
            {title} - {process.env.REACT_APP_APP_NAME}
          </title>
        </Helmet>
        {children}
      </div>
    );
  }
);
