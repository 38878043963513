import { SNQBreadcrumbPath } from 'snq/components/Breadcrumb/SNQBreadcrumb';
import {
  getAdministrationBreadcrumb,
  PATH_ADMINISTRATION
} from 'snq/helpers/path';

export const PATH_IDENTITIES = `${PATH_ADMINISTRATION}/identities`;
export const PATH_IDENTITIES_CREATE = `${PATH_IDENTITIES}/create`;
export const PATH_IDENTITIES_DETAIL = `${PATH_IDENTITIES}/:identityId`;
export const PATH_IDENTITIES_EDIT = `${PATH_IDENTITIES}/:identityId/edit`;
export const PATH_IDENTITIES_DELEGATIONS_DETAILS = `${PATH_IDENTITIES}/:identityId/delegations/:delegationId`;

export const getIdentityBreadcrumb = (): SNQBreadcrumbPath[] => {
  return [
    ...getAdministrationBreadcrumb(),
    {
      path: PATH_IDENTITIES,
      label: 'Identities'
    }
  ];
};
