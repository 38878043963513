/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import type { AppThunk } from 'store';
import axios from 'utils/apiClient/axios';
import objFromArray from 'utils/objFromArray';
import type {
  Board,
  Card,
  CheckItem,
  Checklist,
  Comment,
  List,
  Member
} from 'snq/model';

interface KanbanState {
  isLoaded: boolean;
  lists: {
    byId: Record<string, List>;
    allIds: string[];
  };
  cards: {
    byId: Record<string, Card>;
    allIds: string[];
  };
  members: {
    byId: Record<string, Member>;
    allIds: string[];
  };
}

const initialState: KanbanState = {
  isLoaded: false,
  lists: {
    byId: {},
    allIds: []
  },
  cards: {
    byId: {},
    allIds: []
  },
  members: {
    byId: {},
    allIds: []
  }
};

const slice = createSlice({
  name: 'kanban',
  initialState,
  reducers: {
    getBoard(state: KanbanState, action: PayloadAction<{ board: Board }>) {
      const { board } = action.payload;

      state.lists.byId = objFromArray(board.lists);
      state.lists.allIds = Object.keys(state.lists.byId);
      state.cards.byId = objFromArray(board.cards);
      state.cards.allIds = Object.keys(state.cards.byId);
      state.members.byId = objFromArray(board.members);
      state.members.allIds = Object.keys(state.members.byId);
      state.isLoaded = true;
    },
    createList(state: KanbanState, action: PayloadAction<{ list: List }>) {
      const { list } = action.payload;

      state.lists.byId[list.id] = list;
      state.lists.allIds.push(list.id);
    },
    updateList(state: KanbanState, action: PayloadAction<{ list: List }>) {
      const { list } = action.payload;

      state.lists.byId[list.id] = list;
    },

    clearList(state: KanbanState, action: PayloadAction<{ listId: string }>) {
      const { listId } = action.payload;
      const { card_ids } = state.lists.byId[listId];

      state.lists.byId[listId].card_ids = [];
      state.cards.byId = _.omit(state.cards.byId, card_ids);
      _.pull(state.cards.allIds, ...card_ids);
    },
    deleteList(state: KanbanState, action: PayloadAction<{ list: List }>) {
      const { list } = action.payload;

      state.lists.byId = _.omit(state.lists.byId, list.id);
      _.pull(state.lists.allIds, list.id);
    },
    createCard(
      state: KanbanState,
      action: PayloadAction<{ payload: { card: Card } }>
    ) {
      const { payload } = action.payload;
      const { card } = payload;

      state.cards.byId[card.id] = card;
      state.cards.allIds.push(card.id);
      state.lists.byId[card.list.id].card_ids.push(card.id);
    },
    updateCard(
      state: KanbanState,
      action: PayloadAction<{ payload: { card: Card } }>
    ) {
      const { payload } = action.payload;
      const { card } = payload;

      _.merge(state.cards.byId[card.id], card);
    },
    moveCard(
      state: KanbanState,
      action: PayloadAction<{
        cardId: string;
        position: number;
        listId: string;
        destinationList: string;
      }>
    ) {
      const { cardId, position, listId, destinationList } = action.payload;

      // Remove card from source list
      _.pull(state.lists.byId[listId].card_ids, cardId);

      // If listId arg exists, it means that
      // we have to add the card to the new list
      if (destinationList) {
        state.cards.byId[cardId].list_id = destinationList;
        state.lists.byId[destinationList].card_ids.splice(position, 0, cardId);
      } else {
        state.lists.byId[listId].card_ids.splice(position, 0, cardId);
      }
    },
    deleteCard(state: KanbanState, action: PayloadAction<{ cardId: string }>) {
      const { cardId } = action.payload;
      const { list_id } = state.cards.byId[cardId];

      state.cards.byId = _.omit(state.cards.byId, cardId);
      _.pull(state.cards.allIds, cardId);
      _.pull(state.lists.byId[list_id].card_ids, cardId);
    },
    addComment(
      state: KanbanState,
      action: PayloadAction<{ comment: Comment }>
    ) {
      const { comment } = action.payload;
      const card = state.cards.byId[comment.card_id];

      card.comments.push(comment);
    },
    addChecklist(
      state: KanbanState,
      action: PayloadAction<{ cardId: string; checklist: Checklist }>
    ) {
      const { cardId, checklist } = action.payload;
      const card = state.cards.byId[cardId];

      card.checklists.push(checklist);
    },
    updateChecklist(
      state: KanbanState,
      action: PayloadAction<{ cardId: string; checklist: Checklist }>
    ) {
      const { cardId, checklist } = action.payload;
      const card = state.cards.byId[cardId];

      card.checklists = _.map(card.checklists, (_checklist) => {
        if (_checklist.id === checklist.id) {
          return checklist;
        }

        return _checklist;
      });
    },
    deleteChecklist(
      state: KanbanState,
      action: PayloadAction<{ cardId: string; checklistId: string }>
    ) {
      const { cardId, checklistId } = action.payload;
      const card = state.cards.byId[cardId];

      card.checklists = _.reject(card.checklists, { id: checklistId });
    },
    addCheckItem(
      state: KanbanState,
      action: PayloadAction<{
        cardId: string;
        checklistId: string;
        checkItem: CheckItem;
      }>
    ) {
      const { cardId, checklistId, checkItem } = action.payload;
      const card = state.cards.byId[cardId];

      _.assign(card, {
        checklists: _.map(card.checklists, (checklist) => {
          if (checklist.id === checklistId) {
            _.assign(checklist, {
              checkItems: [...checklist.checkItems, checkItem]
            });
          }

          return checklist;
        })
      });
    },
    updateCheckItem(
      state: KanbanState,
      action: PayloadAction<{
        cardId: string;
        checklistId: string;
        checkItem: CheckItem;
      }>
    ) {
      const { cardId, checklistId, checkItem } = action.payload;
      const card = state.cards.byId[cardId];

      card.checklists = _.map(card.checklists, (checklist) => {
        if (checklist.id === checklistId) {
          _.assign(checklist, {
            checkItems: _.map(checklist.checkItems, (_checkItem) => {
              if (_checkItem.id === checkItem.id) {
                return checkItem;
              }

              return _checkItem;
            })
          });
        }

        return checklist;
      });
    },
    deleteCheckItem(
      state: KanbanState,
      action: PayloadAction<{
        cardId: string;
        checklistId: string;
        checkItemId: string;
      }>
    ) {
      const { cardId, checklistId, checkItemId } = action.payload;
      const card = state.cards.byId[cardId];

      card.checklists = _.map(card.checklists, (checklist) => {
        if (checklist.id === checklistId) {
          _.assign(checklist, {
            checkItems: _.reject(checklist.checkItems, { id: checkItemId })
          });
        }

        return checklist;
      });
    }
  }
});

export const { reducer } = slice;

export const getBoard = (details): AppThunk => async (dispatch) => {
  dispatch(slice.actions.getBoard({ board: details }));
};

export const createList = (list: List): AppThunk => async (dispatch) => {
  dispatch(slice.actions.createList({ list }));
};

export const updateList = (list: List): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateList({ list }));
};

export const clearList = (listId: string): AppThunk => async (dispatch) => {
  await axios.post('/management/lists/lists/clear', {
    listId
  });

  dispatch(slice.actions.clearList({ listId }));
};

export const deleteList = (list: List): AppThunk => async (dispatch) => {
  dispatch(slice.actions.deleteList({ list }));
};

export const createCard = (card: Card): AppThunk => async (dispatch) => {
  dispatch(slice.actions.createCard({ payload: { card } }));
};

export const updateCard = (card: Card): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateCard({ payload: { card } }));
};

export const deleteCard = (cardId: string): AppThunk => async (dispatch) => {
  await axios.post('/management/lists/cards/remove', {
    cardId
  });

  dispatch(slice.actions.deleteCard({ cardId }));
};

export const addComment = (comment): AppThunk => async (dispatch) => {
  dispatch(slice.actions.addComment({ comment }));
};

export const addChecklist = (cardId: string, name: string): AppThunk => async (
  dispatch
) => {
  const response = await axios.post<{ checklist: Checklist }>(
    '/management/lists/checklists/new',
    {
      cardId,
      name
    }
  );
  const { checklist } = response.data;

  dispatch(
    slice.actions.addChecklist({
      cardId,
      checklist
    })
  );
};

export const updateChecklist = (
  cardId: string,
  checklistId: string,
  update: any
): AppThunk => async (dispatch) => {
  const response = await axios.post<{ checklist: Checklist }>(
    '/management/lists/checklists/update',
    {
      cardId,
      checklistId,
      update
    }
  );
  const { checklist } = response.data;

  dispatch(
    slice.actions.updateChecklist({
      cardId,
      checklist
    })
  );
};

export const deleteChecklist = (
  cardId: string,
  checklistId: string
): AppThunk => async (dispatch) => {
  await axios.post('/management/lists/checklists/remove', {
    cardId,
    checklistId
  });

  dispatch(
    slice.actions.deleteChecklist({
      cardId,
      checklistId
    })
  );
};

export const addCheckItem = (
  cardId: string,
  checklistId: string,
  name: string
): AppThunk => async (dispatch) => {
  const response = await axios.post<{ checkItem: CheckItem }>(
    '/management/lists/checkitems/new',
    {
      cardId,
      checklistId,
      name
    }
  );
  const { checkItem } = response.data;

  dispatch(
    slice.actions.addCheckItem({
      cardId,
      checklistId,
      checkItem
    })
  );
};

export const updateCheckItem = (
  cardId: string,
  checklistId: string,
  checkItemId: string,
  update: any
): AppThunk => async (dispatch) => {
  const response = await axios.post<{ checkItem: CheckItem }>(
    '/management/lists/checkitems/update',
    {
      cardId,
      checklistId,
      checkItemId,
      update
    }
  );
  const { checkItem } = response.data;

  dispatch(
    slice.actions.updateCheckItem({
      cardId,
      checklistId,
      checkItem
    })
  );
};

export const deleteCheckItem = (
  cardId: string,
  checklistId: string,
  checkItemId: string
): AppThunk => async (dispatch) => {
  await axios.post('/management/lists/checkitems/remove', {
    cardId,
    checklistId,
    checkItemId
  });

  dispatch(
    slice.actions.deleteCheckItem({
      cardId,
      checklistId,
      checkItemId
    })
  );
};

export default slice;

export const moveCard = (
  listId: string,
  cardId: string,
  destinationList: string,
  position: number
): AppThunk => async (dispatch) => {
  dispatch(
    slice.actions.moveCard({
      cardId,
      position,
      listId,
      destinationList
    })
  );
};
