import type { FC } from 'react';
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';
import type { Theme } from 'theme';

interface FeaturesProps {
  className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingTop: 128,
    paddingBottom: 128
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  }
}));

const Features: FC<FeaturesProps> = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Container maxWidth="lg">
        <Typography
          component="p"
          variant="overline"
          color="secondary"
          align="center"
        >
          Todas las ventajas de la plataforma
        </Typography>
        <Typography variant="h1" align="center" color="textPrimary">
          Transparencia y seguridad
        </Typography>
        <Box mt={8}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Box display="flex">
                <Avatar className={classes.avatar}>01</Avatar>
                <Box ml={2}>
                  <Typography variant="h4" gutterBottom color="textPrimary">
                    Información verificada y actualizada
                  </Typography>
                  <Typography variant="body1" color="textPrimary">
                    La información que se muestra en la plataforma es verificada
                    y actualizada constantemente. Dando tranquilidad al
                    inversor.
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box display="flex">
                <Avatar className={classes.avatar}>02</Avatar>
                <Box ml={2}>
                  <Typography variant="h4" gutterBottom color="textPrimary">
                    Soporte 24/7
                  </Typography>
                  <Typography variant="body1" color="textPrimary">
                    El equipo de soporte está disponible las 24 horas del día, y
                    siempre atento a cualquier incidencia.
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box display="flex">
                <Avatar className={classes.avatar}>03</Avatar>
                <Box ml={2}>
                  <Typography variant="h4" gutterBottom color="textPrimary">
                    Legalizado y regulado por todos los entes publicos.
                  </Typography>
                  <Typography variant="body1" color="textPrimary" gutterBottom>
                    Con el apoyo y patrocinio de todas las instituciones de la
                    Republica Argentina.
                  </Typography>
                  <Button
                    variant="outlined"
                    component="a"
                    href="#"
                    target="_blank"
                  >
                    Ver Documentación Legal
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

Features.propTypes = {
  className: PropTypes.string
};

export default Features;
