import React, { ReactNode } from 'react';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardHeaderProps,
  Divider
} from '@material-ui/core';
import { SNQEditIconButton } from 'snq/components/Btns';

interface SNQCardProps extends CardHeaderProps {
  children: ReactNode;
  editRoute?: string;
}

export const SNQCard = ({
  title,
  subheader,
  children,
  action,
  avatar,
  editRoute
}: SNQCardProps) => {
  return (
    <Card>
      <CardHeader
        title={title}
        subheader={subheader}
        avatar={avatar}
        action={action}
      />
      <Divider />
      <CardContent>{children}</CardContent>
      {editRoute && (
        <CardActions disableSpacing>
          <SNQEditIconButton path={editRoute} />
        </CardActions>
      )}
    </Card>
  );
};
