import React, { ReactElement } from 'react';
import clsx from 'clsx';
import { Grid, makeStyles } from '@material-ui/core';
import type { Theme } from 'theme';

interface HeaderProps {
  className?: string;
  breadcrumb?: JSX.Element | ReactElement;
  actions?: ReactElement | ReactElement[];
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  action: {
    marginBottom: theme.spacing(1),
    '& + &': {
      marginLeft: theme.spacing(1)
    }
  }
}));

export const SNQHeader = ({
  className,
  breadcrumb,
  actions,
  ...rest
}: HeaderProps) => {
  const classes = useStyles();

  return (
    <Grid
      className={clsx(classes.root, className)}
      container
      justify="space-between"
      spacing={3}
      {...rest}
    >
      <Grid item>{breadcrumb}</Grid>
      <Grid item>{actions}</Grid>
    </Grid>
  );
};
