import type { FC, ReactNode } from 'react';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { useAuth } from 'hooks';

interface GuestGuardProps {
  children?: ReactNode;
}

const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Redirect to="/app/account" />;
  }

  return <>{children}</>;
};

export default GuestGuard;
