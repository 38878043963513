import { styled, colors } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

export const CheckCircleIconStyled = styled(CheckCircleIcon)({
  fill: '#0580ea'
});

export const CancelIconStyled = styled(CancelIcon)({
  fill: colors.red[900]
});
