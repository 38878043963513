import { SNQBreadcrumbPath } from 'snq/components/Breadcrumb/SNQBreadcrumb';
import {
  getAdministrationBreadcrumb,
  PATH_ADMINISTRATION
} from 'snq/helpers/path';

export const PATH_INVOICES = `${PATH_ADMINISTRATION}/invoices`;
export const PATH_INVOICES_CREATE = `${PATH_INVOICES}/create`;
export const PATH_INVOICES_DETAIL = `${PATH_INVOICES}/:invoiceId`;
export const PATH_INVOICES_EDIT = `${PATH_INVOICES}/:invoiceId/edit`;

export const PATH_INVOICES_CREATE_TEST = `${PATH_INVOICES}/createTest`;

export const getInvoiceBreadcrumb = (): SNQBreadcrumbPath[] => {
  return [
    ...getAdministrationBreadcrumb(),
    {
      path: PATH_INVOICES,
      label: 'Invoices'
    }
  ];
};
