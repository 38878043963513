/* eslint-disable @typescript-eslint/no-use-before-define */
import type { FC, ReactNode } from 'react';
/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Link as RouterLink, matchPath, useLocation } from 'react-router-dom';

import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles,
  Typography
} from '@material-ui/core';
import Logo from 'components/Logo';
import { APP_VERSION } from 'types/constants';
import { MANAGEMENT_NAVBAR } from 'snq/modules/navbar/management';
import { REPORT_NAVBAR } from 'snq/modules/navbar/reports';
import { ADMINISTRATION_NAVBAR } from 'snq/modules/navbar/administration';
import { FINANCIAL_NAVBAR } from 'snq/modules/navbar/financial';
import NavItem from 'layouts/DashboardLayout/components/NavItem';
import { COMMERCE_NAVBAR } from '../../snq/modules/navbar/commerce';

interface NavBarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

interface Item {
  href?: string;
  icon?: ReactNode;
  info?: ReactNode;
  items?: Item[];
  title: ReactNode;
  enabled?: boolean;
}

interface Section {
  items: Item[];
  subheader: ReactNode;
  enabled: boolean;
}

const sections: Section[] = [
  ...REPORT_NAVBAR,
  ...ADMINISTRATION_NAVBAR,
  ...MANAGEMENT_NAVBAR,
  ...COMMERCE_NAVBAR,
  ...FINANCIAL_NAVBAR
];

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth
}: {
  acc: any[];
  pathname: string;
  item: Item;
  depth: number;
}) {
  const key = item.href;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

function renderNavItems({
  items,
  pathname,
  depth = 0
}: {
  items: Item[];
  pathname: string;
  depth?: number;
}) {
  return (
    <List disablePadding>
      {items
        .filter((item) => item.enabled)
        .reduce(
          (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
          []
        )}
    </List>
  );
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar: FC<NavBarProps> = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          {sections
            .filter((section) => section.enabled)
            .map((section, i) => (
              <List
                key={String(section.subheader) + i}
                subheader={
                  <ListSubheader disableGutters disableSticky>
                    {section.subheader}
                  </ListSubheader>
                }
              >
                {renderNavItems({
                  items: section.items,
                  pathname: location.pathname
                })}
              </List>
            ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  const version = (
    <Typography
      style={{ paddingLeft: '16px' }}
      variant="caption"
      color="textSecondary"
    >
      Version {APP_VERSION}
    </Typography>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
          {version}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
          {version}
        </Drawer>
      </Hidden>
    </>
  );
};

export default NavBar;
