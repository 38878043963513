import type { FC } from 'react';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { create } from 'jss';
import rtl from 'jss-rtl';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import { jssPreset, StylesProvider, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import GlobalStyles from 'components/GlobalStyles';
import ScrollReset from 'components/ScrollReset';
import CookiesNotification from 'components/CookiesNotification';
import GoogleAnalytics from 'components/GoogleAnalytics';
import SettingsNotification from 'components/SettingsNotification';
import { AuthProvider } from 'contexts/Auth0Context';
import { useSettings } from 'hooks';
import { createTheme } from 'theme';
import routes, { renderRoutes } from 'routes';
import './translations';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const App: FC = () => {
  const { settings } = useSettings();
  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: process.env.REACT_APP_APP_NAME
  });

  return (
    <ThemeProvider theme={theme}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <SnackbarProvider dense maxSnack={3}>
            <Router>
              <AuthProvider>
                <GlobalStyles />
                <ScrollReset />
                {process.env.NODE_ENV === 'development' ? null : (
                  <GoogleAnalytics />
                )}
                <CookiesNotification />
                <SettingsNotification />
                {renderRoutes(routes)}
              </AuthProvider>
            </Router>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  );
};

export default App;
