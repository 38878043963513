import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { auth0Config } from 'config';
import { useHistory } from 'react-router-dom';

export const AuthProvider = ({ children }) => {
  const history = useHistory();

  // eslint-disable-next-line no-unused-vars
  const onRedirectCallback = (appState) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={auth0Config.domain}
      clientId={auth0Config.client_id}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      audience={auth0Config.audience}
    >
      {children}
    </Auth0Provider>
  );
};
