import GuestGuard from 'components/GuestGuard';
import { lazy } from 'react';

export const CORE_MODULE = [
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('views/core/auth/LoginView'))
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('views/core/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('views/core/auth/RegisterView'))
  },
  {
    exact: true,
    path: '/register-unprotected',
    component: lazy(() => import('views/core/auth/RegisterView'))
  }
];
