import React, { useEffect, useState } from 'react';
import { Alert } from '@material-ui/lab';
import { BaseError } from 'snq/model/baseEntity';
import { Box } from '@material-ui/core';

interface FormErrorProps {
  error: BaseError;
}

export const SNQFormError = ({ error }: FormErrorProps) => {
  const [isAlertVisible, setAlertVisible] = useState<boolean>(false);
  useEffect(() => {
    if (error && Object.keys(error).length > 0) {
      setAlertVisible(true);
    } else setAlertVisible(false);
  }, [error]);
  if (!error) return null;

  if (error.error) return <SNQFormError error={error.error} />;

  if (typeof error === 'string') {
    return (
      isAlertVisible && (
        <Box width="100%">
          <Alert onClose={() => setAlertVisible(false)} severity="warning">
            <p>
              <strong
                style={{
                  marginRight: 10,
                  minWidth: 100,
                  display: 'inline-block'
                }}
              >
                Error:
              </strong>
              {String(error)}
            </p>
          </Alert>
        </Box>
      )
    );
  }

  return (
    isAlertVisible && (
      <Box width="100%">
        <Alert onClose={() => setAlertVisible(false)} severity="warning">
          {Object.keys(error).map((key) => (
            <p key={key} style={{ paddingBottom: 8 }}>
              <strong
                style={{
                  marginRight: 10,
                  minWidth: 100,
                  display: 'inline-block'
                }}
              >
                {key}:
              </strong>
              {Array.isArray(error[key])
                ? String((error[key] as string[]).join(', '))
                : String(error[key])}
            </p>
          ))}
        </Alert>
      </Box>
    )
  );
};
