import { enumToArray } from 'snq/helpers/enumToArray';
import { Member, Address, LocationRoles, Identity } from 'snq/model';
import { BaseEntity } from './baseEntity';

import { User } from './users';

export interface Organization extends BaseEntity {
  name: string;
  email: string;
  verified: boolean;
  phone: string;
  country: string;
  address?: Address;
  kind: string;
  identifier: string;
  identity: Identity;
  image?: string;
  id: number;
}

export interface Authorization extends BaseEntity {
  organization: Organization;
  user: User;
  role: string;
  id: number;
}

export interface Delegation extends BaseEntity {
  organization: Organization;
  identity: Identity;
  role: string;
  id: number;
}

export interface Location extends BaseEntity {
  organization: Organization;
  address: Address;
  id: number;
  role: LocationRoles;
}

export interface Team extends BaseEntity {
  name: string;
  description: string;
  role: TeamRoles;
  organization: Organization;
  users: Member[];
}

// eslint-disable-next-line no-shadow
export enum OrganizationKinds {
  personal,
  business
}

export enum TeamRoles {
  reader,
  tester,
  writer,
  maintainer,
  administrator
}

export const ORGANIZATION_KIND_OPTIONS = enumToArray(OrganizationKinds);
