/* eslint-disable */
import { useAuth0 } from '@auth0/auth0-react';
import { useRef } from 'react';
import { useCurrentIdentity } from 'snq/hooks/core/useCurrentIdentity';

interface useHeadersConfig {
  ContentType?: string;
  Authorization?: string;
}

export const useHeaders = ({
  ContentType = 'application/json',
  Authorization = undefined
}: useHeadersConfig) => {
  const { identity } = useCurrentIdentity();
  const ref = useRef<string>();
  const { getAccessTokenSilently } = useAuth0();
  const getHeaders = async () => {
    let token = ref.current;
    if (!token) {
      const t = await getAccessTokenSilently();
      token = t;
      ref.current = token;
    }
    return {
      'Content-Type': ContentType,
      Authorization: Authorization || token,
      'x-identity-identifier': identity && `${identity.id}`
    };
  };

  return { getHeaders };
};
