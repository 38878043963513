import { BaseEntity } from './baseEntity';

export interface Address extends BaseEntity {
  address1: string;
  address2: string;
  city: string;
  country: string;
  verified: boolean;
  name: string;
  phone: string;
  state: string;
  zip: string;
  relationId?: number;
}

// eslint-disable-next-line no-shadow
export enum LocationRoles {
  main = 'main',
  secondary = 'secondary'
}
