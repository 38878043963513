import { SORT_TYPE } from './sort';

export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_PAGE_INIT = 1;
export const DEFAULT_META = {
  pagination: {
    perPage: undefined,
    totalPages: undefined,
    totalObjects: -1
  }
};

export interface MetaInformation {
  pagination: {
    perPage: number;
    totalPages: number;
    totalObjects: number;
  };
}

export interface PaginationParams {
  per_page: number;
  page: number;
  sortBy: { id: string; desc: boolean }[];
  search?: string;
  query?: string;
  filter?: string;
}

export interface AutocompleteSearchParams extends PaginationParams {
  query: string;
}

export interface EntitySearch {
  query: string;
}

export interface SortByParams {
  sort_field: string;
  sort_order: SORT_TYPE;
}
