// eslint-disable-next-line no-shadow
export enum CURRENCY {
  EURO = 978,
  USD = 840,
  ADA = 990001
}

export interface CurrencyOption {
  label: string;
  value: CURRENCY;
}

export const CURRENCY_OPTIONS: CurrencyOption[] = [
  {
    label: 'EURO',
    value: CURRENCY.EURO
  },
  {
    label: 'USD',
    value: CURRENCY.USD
  },
  {
    label: 'ADA',
    value: CURRENCY.ADA
  }
];
