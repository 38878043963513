import { useState } from 'react';
import { Identity, LOCAL_STORAGE_KEYS } from 'snq/model';

export const getLastIdentity = (identities: Identity[]): Identity => {
  try {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.lastIdentity));
  } catch (e) {
    return identities[0];
  }
};

export const useCurrentIdentity = (identities?: Identity[]) => {
  const [identity, updateIdentity] = useState<Identity>(
    getLastIdentity(identities)
  );
  const setIdentity = (newIdentity: Identity) => {
    localStorage.setItem(
      LOCAL_STORAGE_KEYS.lastIdentity,
      JSON.stringify(newIdentity)
    );
    updateIdentity(identity);
  };
  return {
    setIdentity,
    identity
  };
};
