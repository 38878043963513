import React, { lazy } from 'react';
import { PieChart } from 'react-feather';
import { Trans } from 'react-i18next';
import { PATH_INVOICES } from 'snq/helpers/paths/invoices';
import {
  PATH_REPORTS_DASHBOARD,
  PATH_REPORTS_DASHBOARD_ALTERNATIVE
} from 'snq/helpers/paths/reports';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';

export const FINANCIAL_MODULE = [
  {
    exact: true,
    path: PATH_REPORTS_DASHBOARD,
    component: lazy(() => import('views/reports/DashboardView'))
  }
];

export const FINANCIAL_NAVBAR = [
  {
    subheader: <Trans>Financial</Trans>,
    enabled: true, // TODO: Enable when valid
    items: [
      {
        title: <Trans>Financial Dashboard</Trans>,
        icon: PieChart,
        href: PATH_REPORTS_DASHBOARD_ALTERNATIVE,
        enabled: false // TODO: Enable when valid
      },
      {
        title: <Trans>Invoices</Trans>,
        href: PATH_INVOICES,
        icon: ReceiptIcon,
        enabled: true // TODO: Enable when valid
      }
    ]
  }
];
