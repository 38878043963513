import { SNQBreadcrumbPath } from 'snq/components/Breadcrumb/SNQBreadcrumb';
import {
  buildPathParams,
  getCommerceBreadcrumb,
  getManagementBreadcrumb,
  PATH_COMMERCE
} from 'snq/helpers/path';

export const PATH_PRODUCTS = `${PATH_COMMERCE}/products`;
export const PATH_PRODUCTS_CREATE = `${PATH_PRODUCTS}/create`;
export const PATH_PRODUCTS_DETAIL = `${PATH_PRODUCTS}/:productId`;
export const PATH_PRODUCTS_EDIT = `${PATH_PRODUCTS}/:productId/edit`;

export const getProductBreadcrumb = (): SNQBreadcrumbPath[] => {
  return [
    ...getCommerceBreadcrumb(),
    {
      path: PATH_PRODUCTS,
      label: 'Products'
    }
  ];
};

export const getProductDetailsBreadcrumb = (
  productId: string
): SNQBreadcrumbPath[] => {
  return [
    ...getManagementBreadcrumb(),
    {
      path: buildPathParams({ productId }, PATH_PRODUCTS_DETAIL),
      label: 'Products'
    }
  ];
};
