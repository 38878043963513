import React, { MouseEventHandler } from 'react';
import { IconButton, SvgIcon } from '@material-ui/core';
import { ChevronDown, ChevronUp } from 'react-feather';
import SwapVert from '@material-ui/icons/SwapVert';
import { SORT_TYPE } from 'snq/model';

interface SortBtnProps {
  onClick?: MouseEventHandler;
  direction?: SORT_TYPE;
  isSorted: boolean;
}

export const SNQSortBtn = ({ onClick, direction, isSorted }: SortBtnProps) => {
  if (!isSorted)
    return (
      <IconButton onClick={onClick}>
        <SwapVert />
      </IconButton>
    );
  return (
    <IconButton onClick={onClick}>
      <SvgIcon fontSize="small">
        {direction === SORT_TYPE.desc ? <ChevronDown /> : <ChevronUp />}
      </SvgIcon>
    </IconButton>
  );
};
