import { lazy } from 'react';
import { PATH_ACCOUNT } from 'snq/helpers/path';

export const ACCOUNT_MODULE = [
  {
    exact: true,
    path: PATH_ACCOUNT,
    component: lazy(() => import('views/account/AccountView'))
  }
];
