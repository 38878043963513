import { SNQBreadcrumbPath } from 'snq/components/Breadcrumb/SNQBreadcrumb';
import { getDashboardBreadcrumb, PATH_ADMINISTRATION } from 'snq/helpers/path';

export const PATH_USERS = `${PATH_ADMINISTRATION}/users`;
export const PATH_USERS_CREATE = `${PATH_USERS}/:userId/create`;
export const PATH_USERS_DETAIL = `${PATH_USERS}/:userId`;
export const PATH_USERS_EDIT = `${PATH_USERS}/:userId/edit`;
export const PATH_USERS_AUTHORIZATIONS_DETAILS = `${PATH_USERS_DETAIL}/authorizations/:authorizationId`;

export const getUserBreadcrumb = (): SNQBreadcrumbPath[] => {
  return [
    ...getDashboardBreadcrumb(),
    {
      path: PATH_USERS,
      label: 'Users'
    }
  ];
};
