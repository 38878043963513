import React, { lazy } from 'react';
import {
  Briefcase as BriefcaseIcon,
  PieChart,
  User as UserIcon,
  Users as UsersIcon
} from 'react-feather';
import { Trans } from 'react-i18next';
import {
  PATH_IDENTITIES,
  PATH_IDENTITIES_CREATE,
  PATH_IDENTITIES_DETAIL,
  PATH_IDENTITIES_EDIT
} from 'snq/helpers/paths/identities';
import {
  PATH_ORGANIZATIONS,
  PATH_ORGANIZATIONS_AUTHORIZATIONS_DETAILS,
  PATH_ORGANIZATIONS_CREATE,
  PATH_ORGANIZATIONS_DELEGATIONS_DETAILS,
  PATH_ORGANIZATIONS_DETAIL,
  PATH_ORGANIZATIONS_EDIT,
  PATH_ORGANIZATIONS_LOCATIONS_DETAILS,
  PATH_ORGANIZATIONS_TEAMS_DETAILS
} from 'snq/helpers/paths/organizations';
import { PATH_REPORTS_DASHBOARD } from 'snq/helpers/paths/reports';
import {
  PATH_USERS,
  PATH_USERS_AUTHORIZATIONS_DETAILS,
  PATH_USERS_CREATE,
  PATH_USERS_DETAIL,
  PATH_USERS_EDIT
} from 'snq/helpers/paths/users';

export const ADMINISTRATION_MODULE = [
  {
    exact: true,
    path: PATH_IDENTITIES,
    component: lazy(
      () => import('views/administration/identity/IdentityListView')
    )
  },
  {
    exact: true,
    path: PATH_IDENTITIES_CREATE,
    component: lazy(
      () => import('views/administration/identity/IdentityCreateView')
    )
  },
  {
    exact: true,
    path: PATH_IDENTITIES_DETAIL,
    component: lazy(
      () => import('views/administration/identity/IdentityDetailsView')
    )
  },
  {
    exact: true,
    path: PATH_IDENTITIES_EDIT,
    component: lazy(
      () => import('views/administration/identity/IdentityEditView')
    )
  },
  {
    exact: true,
    path: PATH_ORGANIZATIONS,
    component: lazy(
      () => import('views/administration/organization/OrganizationListView')
    )
  },
  {
    exact: true,
    path: PATH_ORGANIZATIONS_CREATE,
    component: lazy(
      () => import('views/administration/organization/OrganizationCreateView')
    )
  },
  {
    exact: true,
    path: PATH_ORGANIZATIONS_DETAIL,
    component: lazy(
      () => import('views/administration/organization/OrganizationDetailsView')
    )
  },
  {
    exact: true,
    path: PATH_ORGANIZATIONS_EDIT,
    component: lazy(
      () => import('views/administration/organization/OrganizationEditView')
    )
  },
  {
    exact: true,
    path: PATH_ORGANIZATIONS_AUTHORIZATIONS_DETAILS,
    component: lazy(
      () =>
        import(
          'views/administration/organization/Authorizations/AuthorizationDetailsView'
        )
    )
  },
  {
    exact: true,
    path: PATH_ORGANIZATIONS_DELEGATIONS_DETAILS,
    component: lazy(
      () =>
        import(
          'views/administration/organization/Delegations/DelegationDetailsView'
        )
    )
  },
  {
    exact: true,
    path: PATH_ORGANIZATIONS_LOCATIONS_DETAILS,
    component: lazy(
      () =>
        import(
          'views/administration/organization/Locations/LocationDetailsView'
        )
    )
  },
  {
    exact: true,
    path: PATH_ORGANIZATIONS_TEAMS_DETAILS,
    component: lazy(
      () => import('views/administration/organization/Teams/TeamDetailsView')
    )
  },
  {
    exact: true,
    path: PATH_USERS,
    component: lazy(() => import('views/administration/user/UserListView'))
  },
  {
    exact: true,
    path: PATH_USERS_CREATE,
    component: lazy(() => import('views/administration/user/UserCreateView'))
  },
  {
    exact: true,
    path: PATH_USERS_EDIT,
    component: lazy(() => import('views/administration/user/UserEditView'))
  },
  {
    exact: true,
    path: PATH_USERS_DETAIL,
    component: lazy(() => import('views/administration/user/UserDetailView'))
  },
  {
    exact: true,
    path: PATH_USERS_AUTHORIZATIONS_DETAILS,
    component: lazy(
      () =>
        import(
          'views/administration/user/Authorizations/AuthorizationDetailsView'
        )
    )
  }
];

export const ADMINISTRATION_NAVBAR = [
  {
    subheader: <Trans>Administration</Trans>,
    enabled: true, // TODO: Enable when valid
    items: [
      {
        title: <Trans>Admin Dashboard</Trans>,
        icon: PieChart,
        href: PATH_REPORTS_DASHBOARD,
        enabled: false // TODO: Enable when valid
      },
      {
        title: <Trans>Users</Trans>,
        href: PATH_USERS,
        icon: UserIcon,
        enabled: true // TODO: Enable when valid
      },
      {
        title: <Trans>Organizations</Trans>,
        href: PATH_ORGANIZATIONS,
        icon: UsersIcon,
        enabled: true // TODO: Enable when valid
      },
      {
        title: <Trans>Identities</Trans>,
        href: PATH_IDENTITIES,
        icon: BriefcaseIcon,
        enabled: true // TODO: Enable when valid
      }
    ]
  }
];
