import React from 'react';
import styled from 'styled-components';

const FlagImg = styled.img`
  ${(props) => {
    switch (props.size) {
      case 'small':
        return `
      width: 22px;
      height: 22px;
      `;
      default:
        return `
      width: 50px;
      height: 50px;
      `;
    }
  }}

  ${(props) => (props.rounded ? 'border-radius: 50%;' : 'border-radius: 2%;')}
  overflow: hidden;
`;

interface FlagProps {
  country: string;
  size: 'small' | 'medium';
  rounded?: boolean;
}

export const Flag = ({ country, size, rounded = false }: FlagProps) => {
  return (
    <FlagImg
      size={size}
      rounded={rounded}
      src={`/static/country/${country}.svg`}
    />
  );
};
