import React, { ReactElement } from 'react';
import { Button, SvgIcon } from '@material-ui/core';
import { Edit } from 'react-feather';
import { Link } from 'react-router-dom';

interface EditButtonProps {
  children?: string | ReactElement;
  path?: string;
  onClick?: (e: React.ChangeEvent<any>) => void;
}

export const SNQEditButton = ({
  children = 'Edit',
  path,
  onClick
}: EditButtonProps) => {
  return (
    <Button
      color="secondary"
      variant="contained"
      startIcon={
        <SvgIcon fontSize="small">
          <Edit />
        </SvgIcon>
      }
      to={path}
      component={Link}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};
