import { enumToArray } from '../helpers';

export interface Product {
  price?: any;
  id: number;
  name: string;
  description: string;
  category: string;
  tax: number;
  total: number;
}

// eslint-disable-next-line no-shadow
export enum ProductKind {
  personal = 1,
  business = 2
}

export const PRODUCT_KIND_OPTIONS = enumToArray(ProductKind);

export const PRODUCT_EXTENDED_OPTIONS = [
  { code: 'personal', label: 'Personal', id: '1' },
  { code: 'business', label: 'Business', id: '2' }
];
