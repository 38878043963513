import React from 'react';
import { IconButton, SvgIcon } from '@material-ui/core';
import { ArrowRight } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';

interface EditButtonProps {
  path: string;
}

export const SNQViewIconButton = ({ path }: EditButtonProps) => (
  <IconButton component={RouterLink} to={path}>
    <SvgIcon fontSize="small">
      <ArrowRight />
    </SvgIcon>
  </IconButton>
);
