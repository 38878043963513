import React, { lazy } from 'react';
import { Briefcase as BriefcaseIcon, PieChart } from 'react-feather';
import { Trans } from 'react-i18next';
import {
  PATH_PROJECTS,
  PATH_PROJECTS_BOARD_DETAIL,
  PATH_PROJECTS_CREATE,
  PATH_PROJECTS_DETAIL,
  PATH_PROJECTS_EDIT
} from 'snq/helpers/paths/projects';
import { PATH_TASKS_DETAIL } from 'snq/helpers/paths/tasks';
import { PATH_REPORTS_DASHBOARD } from 'snq/helpers/paths/reports';

export const MANAGEMENT_MODULE = [
  {
    exact: true,
    path: PATH_PROJECTS,
    component: lazy(() => import('views/management/projects/ProjectBrowseView'))
  },
  {
    exact: true,
    path: PATH_PROJECTS_CREATE,
    component: lazy(() => import('views/management/projects/ProjectCreateView'))
  },
  {
    exact: true,
    path: PATH_PROJECTS_DETAIL,
    component: lazy(
      () => import('views/management/projects/ProjectDetailsView')
    )
  },
  {
    exact: true,
    path: PATH_TASKS_DETAIL,
    component: lazy(
      () =>
        import(
          'views/management/projects/ProjectDetailsView/Tasks/TaskDetailView'
        )
    )
  },
  {
    exact: true,
    path: PATH_PROJECTS_BOARD_DETAIL,
    component: lazy(
      () => import('views/management/projects/ProjectDetailsView/Boards')
    )
  },
  {
    exact: true,
    path: PATH_PROJECTS_EDIT,
    component: lazy(() => import('views/management/projects/ProjectCreateView'))
  }
];

export const MANAGEMENT_NAVBAR = [
  {
    subheader: <Trans>Management</Trans>,
    enabled: true, // TODO: Enable when valid
    items: [
      {
        title: <Trans>Tasks Dashboard</Trans>,
        icon: PieChart,
        href: PATH_REPORTS_DASHBOARD,
        enabled: false // TODO: Enable when valid
      },
      {
        title: <Trans>Projects</Trans>,
        href: PATH_PROJECTS,
        icon: BriefcaseIcon,
        enabled: true // TODO: Enable when valid
      }
    ]
  }
];
