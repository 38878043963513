import React from 'react';
import { Box, TextField, TextFieldProps } from '@material-ui/core';
import { get } from 'lodash';

export type SNQInputTextProps = TextFieldProps & { formData?: any };

export const SNQInputText = (props: SNQInputTextProps) => {
  const help =
    get(props, `formData.touched.${props.name}`) &&
    get(props, `formData.errors.${props.name}`);
  const error = props.error || Boolean(help);
  const helperText = props.helperText || help;
  const value = props.value || get(props, `formData.values.${props.name}`);

  return (
    <Box mt={3} mb={1}>
      <TextField
        fullWidth
        variant="outlined"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        error={error}
        helperText={helperText}
        value={value}
        inputProps={{
          // eslint-disable-next-line react/jsx-props-no-spreading
          ...(props.inputProps || {}),
          // https://github.com/mui-org/material-ui/pull/19126/files#diff-b6cfcc877df3ad5e6b9742ba7d7e866eR187
          autoComplete: 'new-password'
        }}
        autoComplete="new-password"
      />
    </Box>
  );
};
