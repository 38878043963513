import React from 'react';
import { IconButton, SvgIcon } from '@material-ui/core';
import { Trash } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';

interface DeleteButtonProps {
  path?: string;
  onClick?: () => void;
}

export const SNQDeleteIconButton = ({ path, onClick }: DeleteButtonProps) => (
  <IconButton component={RouterLink} to={path} onClick={onClick}>
    <SvgIcon fontSize="small">
      <Trash />
    </SvgIcon>
  </IconButton>
);
