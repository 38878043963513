import React from 'react';
import { Box, InputAdornment, SvgIcon, TextField } from '@material-ui/core';
import { useHistory } from 'react-router';
import { Search as SearchIcon } from 'react-feather';
import { useTranslation } from 'react-i18next';

export function SNQSearchForm() {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Box p={1} display="flex" justifyContent="flex-end">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          const formData = new FormData(e.currentTarget);
          history.push({ search: `q=${formData.get('search')}` });
          e.currentTarget.reset();
        }}
      >
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon fontSize="small" color="action">
                  <SearchIcon />
                </SvgIcon>
              </InputAdornment>
            )
          }}
          placeholder={t('Search')}
          name="search"
          variant="outlined"
          size="small"
        />
      </form>
    </Box>
  );
}
