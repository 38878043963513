import { lazy } from 'react';
import {
  PATH_INVOICES,
  PATH_INVOICES_CREATE,
  PATH_INVOICES_CREATE_TEST,
  PATH_INVOICES_DETAIL,
  PATH_INVOICES_EDIT
} from 'snq/helpers/paths/invoices';

export const INVOICING_MODULE = [
  {
    exact: true,
    path: PATH_INVOICES,
    component: lazy(() => import('views/financial/invoice/InvoiceListView'))
  },
  {
    exact: true,
    path: PATH_INVOICES_CREATE,
    component: lazy(() => import('views/financial/invoice/InvoiceCreateView'))
  },
  {
    exact: true,
    path: PATH_INVOICES_CREATE_TEST,
    component: lazy(
      () => import('views/financial/invoice/InvoiceCreateTestView')
    )
  },
  {
    exact: true,
    path: PATH_INVOICES_EDIT,
    component: lazy(() => import('views/financial/invoice/InvoiceEditView'))
  },
  {
    exact: true,
    path: PATH_INVOICES_DETAIL,
    component: lazy(() => import('views/financial/invoice/InvoiceDetailView'))
  }
];
