import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Breadcrumbs, Link, Typography } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Trans } from 'react-i18next';

export interface SNQBreadcrumbPath {
  path: string;
  label: string;
}

interface SNQBreadcrumbProps {
  links: SNQBreadcrumbPath[];
  title: string;
  linkLast?: boolean;
}

export const SNQBreadcrumb = ({
  links,
  title,
  linkLast = false
}: SNQBreadcrumbProps) => {
  return (
    <div>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {links.map((b, k) => {
          if (links.length - 1 === k && !linkLast)
            return (
              <Typography variant="body1" color="textPrimary" key={k}>
                <Trans>{b.label}</Trans>
              </Typography>
            );
          return (
            <Link
              key={b.path}
              variant="body1"
              color="inherit"
              to={b.path}
              component={RouterLink}
            >
              <Trans>{b.label}</Trans>
            </Link>
          );
        })}
      </Breadcrumbs>
      <Box my={2}>
        <Typography variant="h3" color="textPrimary">
          <Trans>{title}</Trans>
        </Typography>
      </Box>
    </div>
  );
};
